'use client';

import { redirect } from 'next/navigation';
import { Button, Center, Divider, Flex, PasswordInput, Stack, TextInput } from '@mantine/core';
import { createClient } from '@/lib/supabase/server';

import { login, signup } from './action';
import GithubLogin from './GitHubLogin';
import Image from 'next/image';
import { useState } from 'react';

export default function Login() {
  const [email, setEmail] = useState('');
  const [showSecondStep, setShowSecondStep] = useState(false);

  return (
    <Center className="w-screen h-screen bg-default">
      <Flex
        className={`w-[460px] min-w-[460px] ${showSecondStep ? 'h-[320px]' : 'h-[355px]'} bg-white rounded-xl border border-default shadow-[0_0_4px_0_#0000000D] overflow-hidden transition-all duration-500 ease-out`}
      >
        {/* First */}
        <Stack
          align="center"
          gap={24}
          className={`min-w-[460px] px-6 pt-9 pb-7 ${showSecondStep && 'translate-x-[-460px] h-0'} transition-all duration-500 ease-out`}
        >
          <Stack align="center" gap={0}>
            <Image src="/icons/IconLogo.svg" alt="Logo" width={48} height={48} style={{border: '1px solid #ddd', borderRadius:'0.5rem', padding:'0.1rem', marginBottom:'0.375rem'}} />
            <div className="text-[1.125rem] leading-[1.75rem] font-bold">Welcome to Isoform</div>
            <div className="text-sub text-xs leading-[1.125rem]">Login to get started</div>
          </Stack>

          <GithubLogin />

          <Divider color="#DDDDDD" className="w-12" />

          <form action={() => setShowSecondStep(true)} className="w-full">
            <Stack>
              <TextInput
                type="email"
                placeholder="Enter email"
                required
                onChange={(e) => setEmail(e.currentTarget.value)}
              />
              <Button variant="default" type="submit">
                <Image
                  src="/icons/IconEmail.svg"
                  width={18}
                  height={18}
                  alt="Email"
                  className="mr-1"
                />
                Continue with Email
              </Button>
            </Stack>
          </form>
        </Stack>

        {/* Second */}
        <Stack
          gap={12}
          className={`min-w-[460px] p-6 pt-5 ${showSecondStep && 'translate-x-[-460px]'} transition-all duration-500 ease-out`}
        >
          <Button
            variant="transparent"
            className="py-2 !pl-0 pr-3 self-start"
            onClick={() => setShowSecondStep(false)}
          >
            <Image src="/icons/IconArrowBackward.svg" width={18} height={18} alt="Back" />
            <span className="ml-1 text-xs leading-[1.125rem] font-bold">Back</span>
          </Button>

          <form className="w-full">
            <Stack gap={12}>
              {/* Email */}
              <Stack gap={8}>
                <label htmlFor="email" className="text-xs leading-[1.125rem] text-sub font-bold">
                  EMAIL
                </label>
                <TextInput
                  id="email"
                  name="email"
                  type="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.currentTarget.value)}
                />
              </Stack>

              {/* Password */}
              <Stack gap={8}>
                <label htmlFor="password" className="text-xs leading-[1.125rem] text-sub font-bold">
                  PASSWORD
                </label>
                <PasswordInput id="password" name="password" required />
              </Stack>

              <Stack gap={8}>
                <Button color="#000" formAction={login} type="submit">
                  Login
                </Button>
                <Button variant="default" formAction={signup} type="submit">
                  Sign up
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>
      </Flex>
    </Center>
  );
}
