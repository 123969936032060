'use client';
// Its parent component is Client Component

import { Button } from '@mantine/core';

import Image from 'next/image';

import { loginWithGithub } from './action';

export default function GithubLogin() {
  return (
    <form className="w-full">
      <Button
        formAction={loginWithGithub}
        type="submit"
        variant="filled"
        color="rgba(0,0,0,1)"
        className="w-full min-w-full py-2"
      >
        <Image src="/icons/IconGitHub.svg" width={18} height={18} alt="GitHub" className="mr-1" />
        Login with GitHub
      </Button>
    </form>
  );
}
